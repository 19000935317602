import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

const propTypes = {
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  refElem: PropTypes.object,
  defaultValue: PropTypes.string
};

const defaultProps = {
  error: '',
  isTouched: false,
  label: '',
  iconSrc: '',
  type: 'text',
  placeholder: '',
  value: '',
  className: ''
};

function Input({
  value,
  error,
  isTouched,
  name,
  label,
  iconSrc,
  onChange,
  refElem,
  className,
  placeholder,
  ...rest
}) {
  const id = name;
  const wrapperClasses = classNames([
    'Input',
    { 'Input--has-icon': !!iconSrc },
    { 'Input--invalid': error && isTouched },
    { 'Input--valid': !error && isTouched },
    className
  ]);

  return (
    <div className={wrapperClasses}>
      <label className="Input__label" htmlFor={id}>
        {label}
      </label>

      <span className="Input__message">
        {error && isTouched ? error : ''}
      </span>

      <input 
        type="text"
        id={id} 
        name={name}
        onChange={onChange}
        className="Input__input"
        placeholder={placeholder}
        ref={refElem}
        {...rest}
      />

      {iconSrc && (
        <span className="Input__icon-wrapper">
          <SVG src={iconSrc} className="Input__icon" />
        </span>
      )}
    </div>
  );
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
