import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'gatsby';
import readingTime from 'reading-time';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageStaticToDynamic from './ImageStaticToDynamic';

import DataAuthors from '../data/authors.json';
import DataBlogCategories from '../data/blogCategories.json';

const propTypes = {
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  image: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  author: PropTypes.array,
  btnText: PropTypes.string,
  category: PropTypes.array
};

const defaultProps = {
  tagName: 'article',
  className: '',
  headingLevel: 3,
  btnText: 'Read more'
};

function BlogItemM({ tagName, headingLevel, className, image, title, excerpt, slug, btnText, author, date, category, html }) {
  const classes = classNames(className, 'BlogItemM');
  const TagName = tagName;
  const Heading = `h${headingLevel}`;

  let authorsExist = DataAuthors.items && DataAuthors.items.length && author && author.length;
  let authorFiltered =
    authorsExist && DataAuthors.items.filter(o => author.find(o2 => o.id === o2?.value));

  let catFiltered = DataBlogCategories.items.find(x => x.id === category);
  catFiltered = typeof catFiltered !== 'undefined' ? catFiltered.name : null;

  const dateFormatted =
    !isNaN(new Date(date)) ? moment(date).format('D MMMM YYYY') : null;

  const time = html ? readingTime(html) : null;

  const getAvatarUrl = (src) => {
    if (!src) return null;

    const regex = /\/img\/([^\/]+)$/;
    const matchResult = src.match(regex);

    return matchResult ? matchResult[1] : null;
  }

  return (
    <TagName className={classes}>
      <GatsbyImage image={getImage(image)} alt={title} />

      <div className="BlogItemM__content">
        <div className="BlogItemM__content__top">
          {catFiltered && (
            <div className="BlogItemM__category">{catFiltered}</div>
          )}

          <Heading className="BlogItemM__title">{title}</Heading>

          {/* <p className="BlogItemM__excerpt">{excerpt}</p> */}
        </div>

        <div className="BlogItemM__content__bottom">
          <div className="BlogItemM__info">
            {authorFiltered != null && (
              <div className="BlogItemM__info__item BlogItemM__info__item--user">
                {authorFiltered.map((e, i) => (
                  <div className="BlogItemM__info__user" key={i}>
                    {e.avatar && (
                      <div className="BlogItemM__info__avatar">
                        <div className="BlogItemM__avatar">
                          <ImageStaticToDynamic src={getAvatarUrl(e.avatar)} />
                        </div>
                      </div>
                    )}
                    <span>{e.name}</span>
                  </div>
                ))}
              </div>
            )}
            {dateFormatted && (
              <div className="BlogItemM__info__item">
                {dateFormatted}
              </div>
            )}
            {time && (
              <div className="BlogItemM__info__item">
                {time.text}
              </div>
            )}
          </div>
        </div>

        <Link className="BlogItemM__link" to={slug}>
          {btnText}
        </Link>
      </div>
    </TagName>
  );
}

BlogItemM.propTypes = propTypes;
BlogItemM.defaultProps = defaultProps;

export default BlogItemM;
