import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMemo } from 'react';
import React from 'react';

const ImageStaticToDynamic = ({ alt, src }) => {
  const data = useStaticQuery(graphql`
    query getAllImages {
      allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  const matchedImage = useMemo(
    () => data.allFile.nodes.find(({ relativePath }) => src === relativePath),
    [data, src],
  );

  const image = matchedImage?.childImageSharp?.gatsbyImageData;

  return !!image ? <GatsbyImage alt={alt} image={image} /> : null;
};

export default ImageStaticToDynamic;