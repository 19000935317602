import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import BlogItemM from '../BlogItemM';

class BlogM extends React.Component {

  static defaultProps = {
    data: []
  }

  render() {
    const { className = '', data } = this.props;

    return (
      <section className={classNames('BlogM', className)}>
        <div className="BlogM__container">
          <div className="BlogM__items">
            {data.map(({ title, image, author, slug, date, category, html }, i) => (
              <BlogItemM
                key={i}
                className="BlogM__item"
                image={image}
                title={title}
                author={author}
                slug={slug}
                date={date}
                category={category}
                html={html}
                btnText="Read more"
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

BlogM.propTypes = {
  data: PropTypes.array
};

export default BlogM;
