import React from 'react';
import xss from 'xss';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { orderBy, find, findIndex } from 'lodash';
import ShowMore from 'react-show-more';

import Layout from '../components/Layout';
import BlogM from '../components/sections/BlogM';
import Button from '../components/Button';
import Input from '../components/Input';

import DataBlogCategories from '../data/blogCategories.json';

import BackgroundSrc from '../img/svg/pageBlog/hero_bg.svg';
import IconSearchSrc from '../img/svg/icons/search.svg';
import BackgroundTwoSrc from '../img/svg/pageHome/training_bg.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

class BlogPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      dataFiltered: [],
      selectedCategory: 'All',
      isMainSVGLoaded: false
    };

    this.titleInput = React.createRef();
  }

  componentDidMount() {
    this.getFilteredBlogs();
  }

  handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.getFilteredBlogs();
    }
  }

  handleSearch(e) {
    this.getFilteredBlogs();
  }

  handleTab(e) {
    this.setState({
      selectedCategory: e
    }, () => {
      this.getFilteredBlogs();
    });
  }

  tabSelected(e) {
    let { selectedCategory } = this.state;

    return e === selectedCategory ? true : false
  }

  getFilteredBlogs() {
    let data;
    let { blogs } = this.props;
    let { selectedCategory } = this.state;
    let text = this.titleInput.current.value;

    data = blogs.reduce((prevVal, currentValue) => {
      let isCategory = true;
      let isIncludeTitle = currentValue.title.toLowerCase().includes(text.toLowerCase());
      let isIncludeTag = find(currentValue.tags, (obj) => obj?.label?.toLowerCase() == text.toLowerCase());

      if (selectedCategory.length && selectedCategory !== 'All') {
        isCategory = find(DataBlogCategories?.items, (obj) => obj?.id == currentValue?.category)?.name?.toLowerCase() == selectedCategory?.toLowerCase();
      }

      if ((isIncludeTitle || isIncludeTag) && isCategory) {
        return [...prevVal, currentValue]
      }

      return prevVal
    }, []);

    this.setState({ dataFiltered: data });
  }

  render() {
    const { dataFiltered, isMainSVGLoaded } = this.state;
    const { hero } = this.props;

    let categories = ["All"];
    DataBlogCategories.items.forEach((e) => {
      categories.push(e.name);
    });

    const classNameBackground = classNames('BlogPageHero__background', {
      'BlogPageHero__background--noImage': !isMainSVGLoaded
    });

    return (
      <>
        <div className="BlogPage">
          <section className="BlogPageHero">
            <div className="Layout__background">
              <SVG src={ImageStarsSrc} className="Layout__stars" />
            </div>

            <div className={classNameBackground}>
              <SVG
                src={BackgroundSrc}
                uniquifyIDs={false}
                className="BlogPageHero__background-image"
                onError={error => console.log(error.message)}
                onLoad={(src, hasCache) => this.setState({ isMainSVGLoaded: true })}
              />
            </div>

            <div className="BlogPageHero__container">
              <div className="BlogPageHero__content">
                <div className="BlogPageHero__content__top">
                  <h1 className="BlogPageHero__title">
                    <ReactMarkdown
                      children={hero.title}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </h1>
                  <div className="BlogPageHero__text">
                    <ShowMore
                      lines={4}
                      more='Show more'
                      less='Show less'
                    >
                      <div dangerouslySetInnerHTML={{ __html: xss(hero.description) }} />
                    </ShowMore>
                  </div>
                </div>
                <div className="BlogPageHero__content__bottom">
                  <div className="BlogPageHero__content__bottom__search">
                    <div className="BlogPageHero__search">
                      <Input
                        type="text"
                        name="category"
                        refElem={this.titleInput}
                        onKeyDown={(e) => this.handleInputKeyDown(e)}
                        defaultValue=""
                        iconSrc={IconSearchSrc}
                        className="BlogPageHero__search__input"
                        placeholder="Type title"
                      />

                      <Button
                        type="submit"
                        className="BlogPageHero__search__btn"
                        onClick={this.handleSearch.bind(this)}
                      >
                        Search
                      </Button>
                    </div>
                  </div>

                  <div className="BlogPage__tags">
                    <div className="BlogPage__tags__title">Category</div>
                    {categories != null && categories.map((e, i) => (
                      <div
                        key={i}
                        className={classNames('BlogPage__tags__item', {
                          'BlogPage__tags__item--selected': this.tabSelected(e)
                        })}
                        onClick={() => this.handleTab(e)}
                      >
                        {e}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="BlogPage__blogs">
            <div className="BlogPage__background">
              <SVG
                src={BackgroundTwoSrc}
                uniquifyIDs={false}
                className="BlogPage__background-image"
              />
            </div>
            <div className="BlogPage__container">
              {/* <div className="BlogPage__tags">
                <div className="BlogPage__tags__title">Category</div>
                {categories != null && categories.map((e, i) => (
                  <div
                    key={i} 
                    className={classNames('BlogPage__tags__item', {
                      'BlogPage__tags__item--selected': this.tabSelected(e)
                    })}
                    onClick={() => this.handleTab(e)}
                  >
                    {e}
                  </div>
                ))}
              </div> */}
            </div>

            <div className="BlogPage__blogs__bg">
              <BlogM
                data={dataFiltered}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const BlogPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  let dataBlogs = data.blogs.edges.map(function (elem, i) {
    let slugFrontmatter = elem.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : elem.node.fields.slug;

    return {
      ...{ id: elem.node.id },
      ...{ excerpt: elem.node.excerpt },
      ...{ html: elem.node.html },
      ...elem.node.frontmatter,
      slug: slug // should be last 
    };
  });

  // order by 'order' and 'date' fields
  dataBlogs = orderBy(dataBlogs, [
    (item) => (item.order),
    (item) => (item.date)
  ], ["asc", "desc"]);

  const mergedData = {
    ...frontmatter,
    ...{ blogs: dataBlogs }
  };

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <BlogPageTemplate {...mergedData} />
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default BlogPage;

export const blogPageQuery = graphql`query BlogPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "blog-page"}}) {
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      hero {
        title
        description
      }
    }
  }
  blogs: allMarkdownRemark(
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        html
        frontmatter {
          order
          title
          slug
          image {
            childImageSharp {
              gatsbyImageData(width: 570, placeholder: BLURRED)
            }
          }
          category
          author {
            label
            value
          }
          tags {
            label
            value
          }
          date
        }
      }
    }
  }
}`;
